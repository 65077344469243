// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-timechef-index-tsx": () => import("./../../../src/pages/timechef/index.tsx" /* webpackChunkName: "component---src-pages-timechef-index-tsx" */),
  "component---src-pages-timechef-privacy-index-tsx": () => import("./../../../src/pages/timechef/privacy/index.tsx" /* webpackChunkName: "component---src-pages-timechef-privacy-index-tsx" */),
  "component---src-pages-timechef-terms-index-tsx": () => import("./../../../src/pages/timechef/terms/index.tsx" /* webpackChunkName: "component---src-pages-timechef-terms-index-tsx" */)
}

